var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ul", { staticClass: "header-navigation__list" }, [
    _c(
      "li",
      {
        class: {
          "header-navigation__el header-navigation__el_submenu header-submenu": true,
          _opened: _vm.activeDropdrown === 1,
        },
      },
      [
        _c(
          "p",
          {
            staticClass: "header-navigation__link header-submenu__head",
            on: {
              click: function ($event) {
                return _vm.toggleSubmenu(1)
              },
            },
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("menu.cryptocurrencies")) + "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "header-submenu__body" }, [
          _c("div", { staticClass: "header-submenu__wrapper" }, [
            _c("div", { staticClass: "header-submenu__section" }, [
              _c("span", { staticClass: "header-submenu__section-headline" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("menu.cryptocurrencies")) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "header-submenu__list" },
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "header-submenu__el submenu-item",
                      attrs: { to: _vm.localePath("/") },
                    },
                    [
                      _c("div", { staticClass: "submenu-item__icon" }, [
                        _c("i", { staticClass: "bx bxs-bar-chart-alt-2" }),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "submenu-item__text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("menu.ranking")) +
                            "\n              "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "header-submenu__el submenu-item",
                      attrs: { to: _vm.localePath("/recently-added") },
                    },
                    [
                      _c("div", { staticClass: "submenu-item__icon" }, [
                        _c("i", { staticClass: "bx bxs-customize" }),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "submenu-item__text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("menu.new")) +
                            "\n              "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "header-submenu__el submenu-item submenu-disabled",
                      attrs: { href: "#" },
                    },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("span", { staticClass: "submenu-item__text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("menu.categories")) +
                            "\n              "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "header-submenu__el submenu-item submenu-disabled",
                      attrs: { href: "#" },
                    },
                    [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("span", { staticClass: "submenu-item__text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("menu.spotlight")) +
                            "\n              "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "header-submenu__el submenu-item",
                      attrs: { to: _vm.localePath("/biggest-gainers") },
                    },
                    [
                      _c("div", { staticClass: "submenu-item__icon" }, [
                        _c("i", { staticClass: "bx bx-bar-chart" }),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "submenu-item__text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("menu.gainers_losers")) +
                            "\n              "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "header-submenu__el submenu-item submenu-disabled",
                      attrs: { href: "#" },
                    },
                    [
                      _vm._m(2),
                      _vm._v(" "),
                      _c("span", { staticClass: "submenu-item__text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("menu.global_charts")) +
                            "\n              "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "header-submenu__el submenu-item submenu-disabled",
                      attrs: { href: "#" },
                    },
                    [
                      _vm._m(3),
                      _vm._v(" "),
                      _c("span", { staticClass: "submenu-item__text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("menu.historical")) +
                            "\n              "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "header-submenu__section" }, [
              _c(
                "div",
                { staticClass: "header-submenu__list" },
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "header-submenu__el submenu-item",
                      attrs: { to: _vm.localePath("/exchanges") },
                    },
                    [
                      _c("div", { staticClass: "submenu-item__icon" }, [
                        _c("i", { staticClass: "bx bxs-bank" }),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "submenu-item__text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("menu.exchanges")) +
                            "\n              "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "header-submenu__el submenu-item",
                      attrs: { to: _vm.localePath("/defi") },
                    },
                    [
                      _c("div", { staticClass: "submenu-item__icon" }, [
                        _c("i", { staticClass: "bx bx-transfer-alt" }),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "submenu-item__text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("menu.defi")) +
                            "\n              "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "header-submenu__el submenu-item submenu-disabled",
                      attrs: { href: "#" },
                    },
                    [
                      _vm._m(4),
                      _vm._v(" "),
                      _c("span", { staticClass: "submenu-item__text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("menu.price_estimates")) +
                            "\n              "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "li",
      {
        class: {
          "header-navigation__el header-navigation__el_submenu header-submenu": true,
          _opened: _vm.activeDropdrown === 4,
        },
      },
      [
        _c(
          "span",
          {
            staticClass: "header-navigation__link header-submenu__head",
            on: {
              click: function ($event) {
                return _vm.toggleSubmenu(4)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("common.exchanges")) + "\n    ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "header-submenu__body" }, [
          _c("div", { staticClass: "header-submenu__wrapper" }, [
            _c(
              "div",
              { staticClass: "header-submenu__list" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "header-submenu__el submenu-item",
                    attrs: { to: _vm.localePath("/exchanges") },
                  },
                  [
                    _c("div", { staticClass: "submenu-item__icon" }, [
                      _c("i", { staticClass: "bx bxs-purchase-tag-alt" }),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "submenu-item__text" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("exchanges.spot")) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "header-submenu__el submenu-item",
                    attrs: { to: _vm.localePath("/exchanges/derivatives") },
                  },
                  [
                    _c("div", { staticClass: "submenu-item__icon" }, [
                      _c("i", {
                        staticClass: "bx bxs-objects-vertical-center",
                      }),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "submenu-item__text" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("exchanges.derivatives")) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "header-submenu__el submenu-item",
                    attrs: { to: _vm.localePath("/exchanges/dex") },
                  },
                  [
                    _c("div", { staticClass: "submenu-item__icon" }, [
                      _c("i", { staticClass: "bx bxs-grid-alt" }),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "submenu-item__text" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("exchanges.dex")) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "header-submenu__el submenu-item",
                    attrs: { to: _vm.localePath("/exchanges/lending") },
                  },
                  [
                    _c("div", { staticClass: "submenu-item__icon" }, [
                      _c("i", { staticClass: "bx bxs-briefcase" }),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "submenu-item__text" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("exchanges.lending")) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "li",
      {
        class: {
          "header-navigation__el header-navigation__el_submenu header-submenu": true,
          _opened: _vm.activeDropdrown === 2,
        },
      },
      [
        _c(
          "span",
          {
            staticClass: "header-navigation__link header-submenu__head",
            on: {
              click: function ($event) {
                return _vm.toggleSubmenu(2)
              },
            },
          },
          [_vm._v("\n      NFT\n    ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "header-submenu__body" }, [
          _c("div", { staticClass: "header-submenu__wrapper" }, [
            _c("div", { staticClass: "header-submenu__section" }, [
              _c("span", { staticClass: "header-submenu__section-headline" }, [
                _vm._v("\n            NFT\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "header-submenu__list" },
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "header-submenu__el submenu-item",
                      attrs: { to: _vm.localePath("/nft") },
                    },
                    [
                      _c("div", { staticClass: "submenu-item__icon" }, [
                        _c("i", { staticClass: "bx bx-images" }),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "submenu-item__text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("menu.nft_collections")) +
                            "\n              "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "header-submenu__el submenu-item",
                      attrs: { to: _vm.localePath("/nft-drop-calendar") },
                    },
                    [
                      _c("div", { staticClass: "submenu-item__icon" }, [
                        _c("i", { staticClass: "bx bxs-calendar" }),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "submenu-item__text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("menu.nft_upcoming")) +
                            "\n              "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "header-submenu__el submenu-item",
                      attrs: { to: _vm.localePath("/play-to-earn") },
                    },
                    [
                      _c("div", { staticClass: "submenu-item__icon" }, [
                        _c("i", { staticClass: "bx bxs-joystick" }),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "submenu-item__text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("menu.p2e")) +
                            "\n              "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm._m(5),
            _vm._v(" "),
            _vm._m(6),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "li",
      {
        class: {
          "header-navigation__el header-navigation__el_submenu header-submenu": true,
          _opened: _vm.activeDropdrown === 3,
        },
      },
      [
        _c(
          "span",
          {
            staticClass: "header-navigation__link header-submenu__head",
            on: {
              click: function ($event) {
                return _vm.toggleSubmenu(3)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("menu.products")) + "\n    ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "header-submenu__body" }, [
          _c("div", { staticClass: "header-submenu__wrapper" }, [
            _c(
              "div",
              { staticClass: "header-submenu__list" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "header-submenu__el submenu-item",
                    attrs: { to: _vm.localePath("/crt") },
                  },
                  [
                    _c("div", { staticClass: "submenu-item__icon" }, [
                      _c("i", { staticClass: "bx bx-coin" }),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "submenu-item__text" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("menu.crt_token")) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "header-submenu__el submenu-item",
                    attrs: { to: _vm.localePath("/ico-events-calendar") },
                  },
                  [
                    _c("div", { staticClass: "submenu-item__icon" }, [
                      _c("i", { staticClass: "bx bx-calendar" }),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "submenu-item__text" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("menu.ico_events")) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "header-submenu__el submenu-item",
                    attrs: { to: _vm.localePath("/airdrop-calendar") },
                  },
                  [
                    _c("div", { staticClass: "submenu-item__icon" }, [
                      _c("i", { staticClass: "bx bx-gift" }),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "submenu-item__text" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("menu.airdrop_calendar")) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "li",
      {
        class: {
          "header-navigation__el header-navigation__el_submenu header-submenu": true,
          _opened: _vm.activeDropdrown === 5,
        },
      },
      [
        _c(
          "span",
          {
            staticClass: "header-navigation__link header-submenu__head",
            on: {
              click: function ($event) {
                return _vm.toggleSubmenu(5)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("menu.info")) + "\n    ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "header-submenu__body" }, [
          _c("div", { staticClass: "header-submenu__wrapper" }, [
            _c(
              "div",
              { staticClass: "header-submenu__list" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "header-submenu__el submenu-item",
                    attrs: { to: _vm.localePath("/faq") },
                  },
                  [
                    _c("div", { staticClass: "submenu-item__icon" }, [
                      _c("i", { staticClass: "bx bx-conversation" }),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "submenu-item__text" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("menu.faq")) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "header-submenu__el submenu-item",
                    attrs: { to: _vm.localePath("/about") },
                  },
                  [
                    _c("div", { staticClass: "submenu-item__icon" }, [
                      _c("i", { staticClass: "bx bx-info-circle" }),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "submenu-item__text" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("menu.about")) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "header-submenu__el submenu-item",
                    attrs: { to: _vm.localePath("/glossary") },
                  },
                  [
                    _c("div", { staticClass: "submenu-item__icon" }, [
                      _c("i", { staticClass: "bx bx-book-bookmark" }),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "submenu-item__text" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("menu.glossary")) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass: "header-submenu__el submenu-item",
                    attrs: { to: _vm.localePath("/articles") },
                  },
                  [
                    _c("div", { staticClass: "submenu-item__icon" }, [
                      _c("i", { staticClass: "bx bxs-news" }),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "submenu-item__text" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("menu.articles")) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "header-submenu__section" }, [
              _c("span", { staticClass: "header-submenu__section-headline" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("common.useful_links")) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "header-submenu__list" }, [
                _c(
                  "a",
                  {
                    staticClass: "header-submenu__el submenu-item",
                    attrs: {
                      href: "/docs/coins_whitepaper.pdf",
                      rel: "nofollow noopener noreferrer",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._m(7),
                    _vm._v(" "),
                    _c("span", { staticClass: "submenu-item__text" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("common.whitepaper")) +
                          "\n                "
                      ),
                      _c("i", { staticClass: "bx bx-link-external" }),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "header-submenu__el submenu-item",
                    attrs: {
                      href: "/docs/coins_onepager.pdf",
                      rel: "nofollow noopener noreferrer",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._m(8),
                    _vm._v(" "),
                    _c("span", { staticClass: "submenu-item__text" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("common.onepager")) +
                          "\n                "
                      ),
                      _c("i", { staticClass: "bx bx-link-external" }),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "header-submenu__el submenu-item",
                    attrs: {
                      href: "/docs/coins_pitchdeck.pdf",
                      rel: "nofollow noopener noreferrer",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._m(9),
                    _vm._v(" "),
                    _c("span", { staticClass: "submenu-item__text" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("common.pitchdeck")) +
                          "\n                "
                      ),
                      _c("i", { staticClass: "bx bx-link-external" }),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "header-submenu__el submenu-item",
                    attrs: {
                      href: "/docs/coins_roadmap.pdf",
                      rel: "nofollow noopener noreferrer",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._m(10),
                    _vm._v(" "),
                    _c("span", { staticClass: "submenu-item__text" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("common.roadmap")) +
                          "\n                "
                      ),
                      _c("i", { staticClass: "bx bx-link-external" }),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "header-submenu__el submenu-item",
                    attrs: {
                      href: "/docs/coins_tokenomics.pdf",
                      rel: "nofollow noopener noreferrer",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._m(11),
                    _vm._v(" "),
                    _c("span", { staticClass: "submenu-item__text" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("common.tokenomics")) +
                          "\n                "
                      ),
                      _c("i", { staticClass: "bx bx-link-external" }),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "submenu-item__icon" }, [
      _c("i", { staticClass: "bx bxs-dashboard" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "submenu-item__icon" }, [
      _c("i", { staticClass: "bx bx-list-ul" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "submenu-item__icon" }, [
      _c("i", { staticClass: "bx bxs-pie-chart-alt-2" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "submenu-item__icon" }, [
      _c("i", { staticClass: "bx bx-history" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "submenu-item__icon" }, [
      _c("i", { staticClass: "bx bx-trending-up" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-submenu__section" }, [
      _c("span", { staticClass: "header-submenu__section-headline" }, [
        _vm._v("On Chain Data"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "header-submenu__list" }, [
        _c(
          "a",
          {
            staticClass: "header-submenu__el submenu-item submenu-disabled",
            attrs: { href: "#" },
          },
          [
            _c("div", { staticClass: "submenu-item__icon" }, [
              _c("i", { staticClass: "bx bx-refresh" }),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "submenu-item__text" }, [
              _vm._v("Dex Pairs"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "header-submenu__el submenu-item submenu-disabled",
            attrs: { href: "#" },
          },
          [
            _c("div", { staticClass: "submenu-item__icon" }, [
              _c("i", { staticClass: "bx bx-line-chart" }),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "submenu-item__text" }, [
              _vm._v("Chain Ranking"),
            ]),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header-submenu__section" }, [
      _c("div", { staticClass: "header-submenu__list" }, [
        _c(
          "a",
          {
            staticClass: "header-submenu__el submenu-item submenu-disabled",
            attrs: { href: "#" },
          },
          [
            _c("div", { staticClass: "submenu-item__icon" }, [
              _c("i", { staticClass: "bx bx-candles" }),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "submenu-item__text" }, [
              _vm._v("Legal  Tender Countries"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "header-submenu__el submenu-item submenu-disabled",
            attrs: { href: "#" },
          },
          [
            _c("div", { staticClass: "submenu-item__icon" }, [
              _c("i", { staticClass: "bx bx-bar-chart-alt" }),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "submenu-item__text" }, [
              _vm._v("Fiats / Companies Rankings"),
            ]),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "submenu-item__icon" }, [
      _c("i", { staticClass: "bx bxs-file" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "submenu-item__icon" }, [
      _c("i", { staticClass: "bx bxs-file-pdf" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "submenu-item__icon" }, [
      _c("i", { staticClass: "bx bxs-file-pdf" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "submenu-item__icon" }, [
      _c("i", { staticClass: "bx bxs-calendar-check" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "submenu-item__icon" }, [
      _c("i", { staticClass: "bx bxs-coin" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }