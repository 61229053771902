<template>
  <ul class="header-navigation__list">
    <li :class="{'header-navigation__el header-navigation__el_submenu header-submenu': true, '_opened': activeDropdrown === 1}">
      <p class="header-navigation__link header-submenu__head" @click="toggleSubmenu(1)">
        {{ $t('menu.cryptocurrencies') }}
      </p>
      <div class="header-submenu__body">
        <div class="header-submenu__wrapper">
          <div class="header-submenu__section">
            <span class="header-submenu__section-headline">
              {{ $t('menu.cryptocurrencies') }}
            </span>
            <div class="header-submenu__list">
              <nuxt-link :to="localePath('/')" class="header-submenu__el submenu-item">
                <div class="submenu-item__icon">
                  <i class="bx bxs-bar-chart-alt-2"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('menu.ranking') }}
                </span>
              </nuxt-link>
              <nuxt-link :to="localePath('/recently-added')" class="header-submenu__el submenu-item">
                <div class="submenu-item__icon">
                  <i class="bx bxs-customize"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('menu.new') }}
                </span>
              </nuxt-link>
              <a href="#" class="header-submenu__el submenu-item submenu-disabled">
                <div class="submenu-item__icon">
                  <i class="bx bxs-dashboard"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('menu.categories') }}
                </span>
              </a>
              <a href="#" class="header-submenu__el submenu-item submenu-disabled">
                <div class="submenu-item__icon">
                  <i class="bx bx-list-ul"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('menu.spotlight') }}
                </span>
              </a>
              <nuxt-link :to="localePath('/biggest-gainers')" class="header-submenu__el submenu-item">
                <div class="submenu-item__icon">
                  <i class="bx bx-bar-chart"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('menu.gainers_losers') }}
                </span>
              </nuxt-link>
              <a href="#" class="header-submenu__el submenu-item submenu-disabled">
                <div class="submenu-item__icon">
                  <i class="bx bxs-pie-chart-alt-2"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('menu.global_charts') }}
                </span>
              </a>
              <a href="#" class="header-submenu__el submenu-item submenu-disabled">
                <div class="submenu-item__icon">
                  <i class="bx bx-history"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('menu.historical') }}
                </span>
              </a>
            </div>
          </div>
          <div class="header-submenu__section">
            <div class="header-submenu__list">
              <nuxt-link :to="localePath('/exchanges')" class="header-submenu__el submenu-item">
                <div class="submenu-item__icon">
                  <i class="bx bxs-bank"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('menu.exchanges') }}
                </span>
              </nuxt-link>
              <nuxt-link :to="localePath('/defi')" class="header-submenu__el submenu-item">
                <div class="submenu-item__icon">
                  <i class="bx bx-transfer-alt"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('menu.defi') }}
                </span>
              </nuxt-link>
              <a href="#" class="header-submenu__el submenu-item submenu-disabled">
                <div class="submenu-item__icon">
                  <i class="bx bx-trending-up"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('menu.price_estimates') }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li :class="{'header-navigation__el header-navigation__el_submenu header-submenu': true, '_opened': activeDropdrown === 4}">
      <span class="header-navigation__link header-submenu__head" @click="toggleSubmenu(4)">
        {{ $t('common.exchanges') }}
      </span>
      <div class="header-submenu__body">
        <div class="header-submenu__wrapper">
          <div class="header-submenu__list">
            <nuxt-link :to="localePath('/exchanges')" class="header-submenu__el submenu-item">
              <div class="submenu-item__icon">
                <i class="bx bxs-purchase-tag-alt"></i>
              </div>
              <span class="submenu-item__text">
                {{ $t('exchanges.spot') }}
              </span>
            </nuxt-link>
            <nuxt-link :to="localePath('/exchanges/derivatives')" class="header-submenu__el submenu-item">
              <div class="submenu-item__icon">
                <i class="bx bxs-objects-vertical-center"></i>
              </div>
              <span class="submenu-item__text">
                {{ $t('exchanges.derivatives') }}
              </span>
            </nuxt-link>
            <nuxt-link :to="localePath('/exchanges/dex')" class="header-submenu__el submenu-item">
              <div class="submenu-item__icon">
                <i class="bx bxs-grid-alt"></i>
              </div>
              <span class="submenu-item__text">
                {{ $t('exchanges.dex') }}
              </span>
            </nuxt-link>
            <nuxt-link :to="localePath('/exchanges/lending')" class="header-submenu__el submenu-item">
              <div class="submenu-item__icon">
                <i class="bx bxs-briefcase"></i>
              </div>
              <span class="submenu-item__text">
                {{ $t('exchanges.lending') }}
              </span>
            </nuxt-link>
          </div>
        </div>
      </div>
    </li>
    <li :class="{'header-navigation__el header-navigation__el_submenu header-submenu': true, '_opened': activeDropdrown === 2}">
      <span class="header-navigation__link header-submenu__head" @click="toggleSubmenu(2)">
        NFT
      </span>
      <div class="header-submenu__body">
        <div class="header-submenu__wrapper">
          <div class="header-submenu__section">
            <span class="header-submenu__section-headline">
              NFT
            </span>
            <div class="header-submenu__list">
              <nuxt-link :to="localePath('/nft')" class="header-submenu__el submenu-item">
                <div class="submenu-item__icon">
                  <i class="bx bx-images"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('menu.nft_collections') }}
                </span>
              </nuxt-link>
              <nuxt-link :to="localePath('/nft-drop-calendar')" class="header-submenu__el submenu-item">
                <div class="submenu-item__icon">
                  <i class="bx bxs-calendar"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('menu.nft_upcoming') }}
                </span>
              </nuxt-link>
              <nuxt-link :to="localePath('/play-to-earn')" class="header-submenu__el submenu-item">
                <div class="submenu-item__icon">
                  <i class="bx bxs-joystick"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('menu.p2e') }}
                </span>
              </nuxt-link>
            </div>
          </div>
          <div class="header-submenu__section">
            <span class="header-submenu__section-headline">On Chain Data</span>
            <div class="header-submenu__list">
              <a href="#" class="header-submenu__el submenu-item submenu-disabled">
                <div class="submenu-item__icon">
                  <i class="bx bx-refresh"></i>
                </div>
                <span class="submenu-item__text">Dex Pairs</span>
              </a>
              <a href="#" class="header-submenu__el submenu-item submenu-disabled">
                <div class="submenu-item__icon">
                  <i class="bx bx-line-chart"></i>
                </div>
                <span class="submenu-item__text">Chain Ranking</span>
              </a>
            </div>
          </div>
          <div class="header-submenu__section">
            <div class="header-submenu__list">
              <a href="#" class="header-submenu__el submenu-item submenu-disabled">
                <div class="submenu-item__icon">
                  <i class="bx bx-candles"></i>
                </div>
                <span class="submenu-item__text">Legal  Tender Countries</span>
              </a>
              <a href="#" class="header-submenu__el submenu-item submenu-disabled">
                <div class="submenu-item__icon">
                  <i class="bx bx-bar-chart-alt"></i>
                </div>
                <span class="submenu-item__text">Fiats / Companies Rankings</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li :class="{'header-navigation__el header-navigation__el_submenu header-submenu': true, '_opened': activeDropdrown === 3}">
      <span class="header-navigation__link header-submenu__head" @click="toggleSubmenu(3)">
        {{ $t('menu.products') }}
      </span>
      <div class="header-submenu__body">
        <div class="header-submenu__wrapper">
          <div class="header-submenu__list">
            <nuxt-link :to="localePath('/crt')" class="header-submenu__el submenu-item">
              <div class="submenu-item__icon">
                <i class="bx bx-coin"></i>
              </div>
              <span class="submenu-item__text">
                {{ $t('menu.crt_token') }}
              </span>
            </nuxt-link>
            <nuxt-link :to="localePath('/ico-events-calendar')" class="header-submenu__el submenu-item">
              <div class="submenu-item__icon">
                <i class="bx bx-calendar"></i>
              </div>
              <span class="submenu-item__text">
                {{ $t('menu.ico_events') }}
              </span>
            </nuxt-link>
            <nuxt-link :to="localePath('/airdrop-calendar')" class="header-submenu__el submenu-item">
              <div class="submenu-item__icon">
                <i class="bx bx-gift"></i>
              </div>
              <span class="submenu-item__text">
                {{ $t('menu.airdrop_calendar') }}
              </span>
            </nuxt-link>
          </div>
        </div>
      </div>
    </li>
    <li :class="{'header-navigation__el header-navigation__el_submenu header-submenu': true, '_opened': activeDropdrown === 5}">
      <span class="header-navigation__link header-submenu__head" @click="toggleSubmenu(5)">
        {{ $t('menu.info') }}
      </span>
      <div class="header-submenu__body">
        <div class="header-submenu__wrapper">
          <div class="header-submenu__list">
            <nuxt-link :to="localePath('/faq')" class="header-submenu__el submenu-item">
              <div class="submenu-item__icon">
                <i class="bx bx-conversation"></i>
              </div>
              <span class="submenu-item__text">
                {{ $t('menu.faq') }}
              </span>
            </nuxt-link>
            <nuxt-link :to="localePath('/about')" class="header-submenu__el submenu-item">
              <div class="submenu-item__icon">
                <i class="bx bx-info-circle"></i>
              </div>
              <span class="submenu-item__text">
                {{ $t('menu.about') }}
              </span>
            </nuxt-link>
            <nuxt-link :to="localePath('/glossary')" class="header-submenu__el submenu-item">
              <div class="submenu-item__icon">
                <i class="bx bx-book-bookmark"></i>
              </div>
              <span class="submenu-item__text">
                {{ $t('menu.glossary') }}
              </span>
            </nuxt-link>
            <nuxt-link :to="localePath('/articles')" class="header-submenu__el submenu-item">
              <div class="submenu-item__icon">
                <i class="bx bxs-news"></i>
              </div>
              <span class="submenu-item__text">
                {{ $t('menu.articles') }}
              </span>
            </nuxt-link>
          </div>
          <div class="header-submenu__section">
            <span class="header-submenu__section-headline">
              {{ $t('common.useful_links') }}
            </span>
            <div class="header-submenu__list">
              <a href="/docs/coins_whitepaper.pdf" rel="nofollow noopener noreferrer" target="_blank" class="header-submenu__el submenu-item">
                <div class="submenu-item__icon">
                  <i class="bx bxs-file"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('common.whitepaper') }}
                  <i class="bx bx-link-external"></i>
                </span>
              </a>
              <a href="/docs/coins_onepager.pdf" rel="nofollow noopener noreferrer" target="_blank" class="header-submenu__el submenu-item">
                <div class="submenu-item__icon">
                  <i class="bx bxs-file-pdf"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('common.onepager') }}
                  <i class="bx bx-link-external"></i>
                </span>
              </a>
              <a href="/docs/coins_pitchdeck.pdf" rel="nofollow noopener noreferrer" target="_blank" class="header-submenu__el submenu-item">
                <div class="submenu-item__icon">
                  <i class="bx bxs-file-pdf"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('common.pitchdeck') }}
                  <i class="bx bx-link-external"></i>
                </span>
              </a>
              <a href="/docs/coins_roadmap.pdf" rel="nofollow noopener noreferrer" target="_blank" class="header-submenu__el submenu-item">
                <div class="submenu-item__icon">
                  <i class="bx bxs-calendar-check"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('common.roadmap') }}
                  <i class="bx bx-link-external"></i>
                </span>
              </a>
              <a href="/docs/coins_tokenomics.pdf" rel="nofollow noopener noreferrer" target="_blank" class="header-submenu__el submenu-item">
                <div class="submenu-item__icon">
                  <i class="bx bxs-coin"></i>
                </div>
                <span class="submenu-item__text">
                  {{ $t('common.tokenomics') }}
                  <i class="bx bx-link-external"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MenuComponent',
  props: {
    active: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      activeDropdrown: 0
    }
  },
  methods: {
    leaveSite(url) {
      this.$nuxt.$emit('leaveSite', url)
    },
    toggleSubmenu(id) {
      if (this.activeDropdrown !== id) {
        this.activeDropdrown = id
      } else {
        this.activeDropdrown = 0
      }
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/common/header.css');
</style>
